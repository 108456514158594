import { useEffect, useState } from 'react';

import { useStripe } from '@stripe/react-stripe-js';
import type { CanMakePaymentResult, PaymentRequest } from '@stripe/stripe-js';

export function usePaymentRequest({
	amount,
	currencyCode,
	label,
}: {
	amount: number;
	currencyCode: string;
	label: string;
}): PaymentRequest | null {
	const stripe = useStripe();
	const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(null);
	const [canMakePayment, setCanMakePayment] = useState<CanMakePaymentResult | null>(null);

	useEffect(() => {
		if (!paymentRequest) return;
		(async () => {
			const cmp = await paymentRequest.canMakePayment();
			setCanMakePayment(cmp);
		})();
	}, [paymentRequest]);

	useEffect(() => {
		if (stripe && paymentRequest && canMakePayment) {
			paymentRequest.update({
				total: {
					amount,
					label,
				},
			});
		}
	}, [amount, canMakePayment, label, paymentRequest, stripe]);

	if (stripe && !paymentRequest) {
		const pr = stripe.paymentRequest({
			// The Payment Request object must be initialized with our Stripe account's country code
			country: 'US',
			currency: currencyCode.toLowerCase(),
			total: {
				label,
				amount,
			},
			requestPayerName: true,
			requestPayerEmail: true,
		});
		setPaymentRequest(pr);
	}

	return canMakePayment && paymentRequest;
}
