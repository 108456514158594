import { useTracking } from '@change-corgi/core/react/tracking';

import type { WithTokenParams } from 'src/app/shared/types';
import type { PaymentMethodSaveOptions, PaymentType } from 'src/app/shared/utils/payments';

import type { NewPaymentMethodPaypalProps } from '../../index.tsx';
import { useData } from '../useData';

type Result = ModelHookResult<
	{
		amount?: number;
		billingAgreementDescription?: string;
		currency: string;
		disabled: boolean;
		email: string;
		flow: paypal.FlowType;
		loading: boolean;
		paymentMethodSaveOptions: PaymentMethodSaveOptions;
		shouldEnableButton?: boolean;
	},
	{
		beforeToken: (paymentType: PaymentType) => Promise<boolean>;
		onTokenError: (paymentType: PaymentType, err: Error) => void;
		onTokenInvalid: (paymentType: PaymentType, err: Error) => void;
		validate: (paymentType: PaymentType) => Promise<boolean>;
		withToken: (params: WithTokenParams) => Promise<void>;
		trackLoadStartTime: () => void;
		trackLoadEndTime: (duration: number) => void;
		prePaymentChecks: (paymentType: PaymentType) => Promise<boolean>;
	}
>;

// eslint-disable-next-line max-lines-per-function
export function useNewPaymentMethodPaypal({
	currencyCode,
	amount,
	loading,
	email,
	paymentMethodSaveOptions,
	useDonationLanguage,
	validationResult,
	validate,
	beforeToken,
	onTokenError,
	onTokenInvalid,
	withToken,
	prePaymentChecks,
}: NewPaymentMethodPaypalProps): Result {
	const track = useTracking();
	const data = useData({
		currencyCode,
		amount,
		loading,
		email,
		paymentMethodSaveOptions,
		useDonationLanguage,
		validationResult,
	});

	return {
		data,
		actions: {
			beforeToken,
			onTokenError,
			onTokenInvalid,
			validate: async (paymentType: PaymentType) => {
				if (await validate(paymentType)) {
					void track('paypal_login_view');
					return true;
				}
				return false;
			},
			withToken: async ({ paymentType, token }: WithTokenParams) => {
				void track('paypal_auth_success');
				return withToken({ paymentType, token });
			},
			prePaymentChecks,
			trackLoadStartTime: () => {
				void track('pp_paypal_button_loading_start_time');
			},
			trackLoadEndTime: (duration: number) => {
				void track('pp_paypal_button_loading_end_time', { duration });
			},
		},
	};
}
