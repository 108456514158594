import type { WithTokenParams } from 'src/app/shared/types';
import type { PaymentMethodSaveOptions, PaymentType } from 'src/app/shared/utils/payments';

import type { NewPaymentMethodSepaProps } from '../../index.tsx';

type Result = ModelHookResult<
	{
		email: string;
		name: string;
		paymentMethodSaveOptions: PaymentMethodSaveOptions;
	},
	{
		beforeToken: (paymentType: PaymentType) => Promise<boolean>;
		onTokenError: (paymentType: PaymentType, err: Error) => void;
		onTokenInvalid: (paymentType: PaymentType, err: Error) => void;
		validate: (paymentType: PaymentType) => Promise<boolean>;
		prePaymentChecks: (paymentType: PaymentType) => Promise<boolean>;
		withToken: (params: WithTokenParams) => Promise<void>;
		onFieldFocus: () => void;
	}
>;

export function useNewPaymentMethodSepa({
	email,
	name,
	paymentMethodSaveOptions,
	validate,
	beforeToken,
	onTokenError,
	onTokenInvalid,
	withToken,
	prePaymentChecks,
	onFieldFocusIban,
}: Omit<NewPaymentMethodSepaProps, 'submitButton'>): Result {
	const data: Result['data'] = {
		name,
		email,
		paymentMethodSaveOptions,
	};

	return {
		data,
		actions: {
			beforeToken,
			onTokenError,
			onTokenInvalid,
			validate,
			prePaymentChecks,
			withToken,
			onFieldFocus: onFieldFocusIban,
		},
	};
}
