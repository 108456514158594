/* eslint-disable @typescript-eslint/consistent-type-assertions */
type Params = {
	email: string;
	firstName: string;
	lastName: string;
	payerEmail?: string;
	payerName?: string;
	useWalletData: boolean;
};
export function getUserInfo({ email, firstName, lastName, payerEmail, payerName, useWalletData }: Params): {
	email: string;
	firstName: string;
	lastName: string;
} {
	if (useWalletData) {
		// TODO: what if wallet returns empty for these fields
		if (payerName?.includes(' ')) {
			const [first, ...remaining] = payerName?.split(' ') ?? [];
			return { email: payerEmail as string, firstName: first, lastName: remaining.join(' ') };
		}

		return { email: payerEmail as string, firstName: payerName as string, lastName: payerName as string };
	}
	return { email, firstName, lastName };
}
