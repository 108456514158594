import type { ModalOptions } from '../../shared/types';

import type { MembershipModalState } from './types';

export function initialState(state: ModalOptions): MembershipModalState {
	return {
		...state,
		acceptMembership: true,
		formState: null,
		validationErrors: null,
	};
}
